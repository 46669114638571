const Footer = () => {
  return (
    <div className="d-flex flex-column">
      <p className="m-2 p-2 text-center main-heading">
        &copy; 2022-2025, BetterSmart LLC by Elena Weber (aka English Witch Elena)
      </p>
    </div>
  )
};

export default Footer;